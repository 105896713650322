<template>
  <div class="app-editor">
    <ckeditor :id="id" :editor="editor" :config="editorConfig" :value="value" @input="handleUpdate" :disabled="readonly"></ckeditor>
  </div>
</template>

<style lang="scss">
.app-editor {
  .ck-editor__editable {
    min-height: 250px;
  }
  &.input-validation-error {
    .ck-editor__editable {
      border: 1px solid #a94442;
      background-color: #f2dede;
    }
  }
}

.app-editor.is-invalid {
  .ck-editor__editable {
    border: 1px solid #a94442;
    background-color: #f2dede;
  }
}

.ck {
  .ck-balloon-panel {
    z-index: 9999;
  }
}
</style>

<script>
import uuid from 'uuid/v1'
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "app-editor",
  components: { ckeditor: CKEditor.component },
  props: {
    id: { type: String, default: 'app-editor-'+ uuid().toString() },
    value: { type: String, default: "" },
    readonly: { type: Boolean, default: false }
  },
  data: function() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [ "undo", "redo", "bold", "italic", "link", "bulletedList", "numberedList" ]
        }
      }
    };
  },
  methods: {
    handleUpdate(val) {
      this.$emit("input", val);
    }
  }
};
</script>
